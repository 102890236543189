[
  {
    "label": "Deutsch",
    "value": "de",
    "enabled": 3,
    "order": 0
  },
  {
    "label": "English (GB)",
    "value": "en",
    "enabled": 3,
    "order": 1
  },
  {
    "label": "English (USA)",
    "value": "us",
    "enabled": 2,
    "order": 3
  },
  {
    "label": "Español",
    "enabled": 3,
    "value": "es",
    "order": 4
  },
  {
    "label": "Français",
    "value": "fr",
    "enabled": 3,
    "order": 5
  },
  {
    "label": "Italiano",
    "value": "it",
    "enabled": 3,
    "order": 6
  },
  {
    "label": "Türkçe",
    "enabled": 3,
    "value": "tr",
    "order": 7
  },
  {
    "label": "汉语",
    "enabled": 3,
    "value": "zh",
    "order": 255
  },
  {
    "value": "qa",
    "enabled": 1,
    "order": 10,
    "label": "English (USA)"
  },
  {
    "value": "qb",
    "enabled": 1,
    "order": 11,
    "label": "English (AUS)"
  },
  {
    "value": "nl",
    "enabled": 1,
    "order": 12,
    "label": "Nederlands"
  },
  {
    "value": "da",
    "enabled": 1,
    "order": 13,
    "label": "Dansk"
  },
  {
    "value": "sv",
    "enabled": 1,
    "order": 14,
    "label": "Svenska"
  },
  {
    "value": "no",
    "enabled": 1,
    "order": 15,
    "label": "Norsk"
  },
  {
    "value": "fi",
    "enabled": 1,
    "order": 16,
    "label": "Suomi"
  },
  {
    "value": "hu",
    "enabled": 1,
    "order": 17,
    "label": "Magyar"
  },
  {
    "value": "pt",
    "enabled": 1,
    "order": 18,
    "label": "Português"
  },
  {
    "value": "ru",
    "enabled": 1,
    "order": 19,
    "label": "русский"
  },
  {
    "value": "sk",
    "enabled": 1,
    "order": 29,
    "label": "slovenčina"
  },
  {
    "value": "cs",
    "enabled": 1,
    "order": 21,
    "label": "čeština"
  },
  {
    "value": "pl",
    "enabled": 1,
    "order": 22,
    "label": "polski"
  },
  {
    "value": "el",
    "enabled": 1,
    "order": 23,
    "label": "Ελληvικά"
  },
  {
    "value": "ro",
    "enabled": 1,
    "order": 24,
    "label": "Română"
  },
  {
    "value": "hr",
    "enabled": 1,
    "order": 25,
    "label": "Hrvatski"
  },
  {
    "value": "sr",
    "enabled": 1,
    "order": 26,
    "label": "Srpski"
  },
  {
    "value": "bg",
    "enabled": 1,
    "order": 27,
    "label": "Български"
  },
  {
    "value": "lv",
    "enabled": 1,
    "order": 28,
    "label": "Latviešu"
  },
  {
    "value": "lt",
    "enabled": 1,
    "order": 29,
    "label": "Lietuvių"
  },
  {
    "value": "et",
    "enabled": 1,
    "order": 30,
    "label": "Eesti"
  },
  {
    "value": "sl",
    "enabled": 1,
    "order": 31,
    "label": "Slovenski"
  },
  {
    "value": "qc",
    "enabled": 1,
    "order": 32,
    "label": "português (BR)"
  },
  {
    "value": "ja",
    "enabled": 1,
    "order": 33,
    "label": "日本語"
  },
  {
    "value": "ar",
    "enabled": 1,
    "order": 34,
    "label": "عربي"
  },
  {
    "value": "he",
    "enabled": 1,
    "order": 35,
    "label": "עברית"
  },
  {
    "value": "qd",
    "enabled": 1,
    "order": 36,
    "label": "Español (MEX)"
  },
  {
    "value": "th",
    "enabled": 1,
    "order": 37,
    "label": "ภาษาไทย (ประเทศไทย)"
  },
  {
    "value": "ko",
    "enabled": 1,
    "order": 38,
    "label": "한국어"
  },
  {
    "value": "ms",
    "enabled": 1,
    "order": 39,
    "label": "Bahasa Melayu"
  },
  {
    "value": "uk",
    "enabled": 1,
    "order": 40,
    "label": "Українська"
  },
  {
    "value": "vi",
    "enabled": 1,
    "order": 41,
    "label": "Tiếng việt"
  }
]