/* eslint-disable @typescript-eslint/no-explicit-any */
export class ObjectHelper {
  /**
   * Checks if two objects are equal by comparing their JSON string representations.
   * @param object1 The first object to compare.
   * @param object2 The second object to compare.
   * @returns true if both objects are equal, otherwise false.
   */
  static equals(object1, object2): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  /**
   * Checks if an object contains a specific property.
   * @param object The object to check.
   * @param property The property name to check for.
   * @returns true if the property exists in the object, otherwise false.
   */
  static has(object, property): boolean {
    return property in object;
  }

  /**
   * Checks if a value is considered "empty". Empty can be:
   * - An empty string, null, or undefined.
   * - An array with a single empty string element.
   * @param obj The value to check.
   * @returns true if the value is considered empty, otherwise false.
   */
  static isEmpty(obj: any): boolean {
    return obj === '' || obj === null || obj === undefined || (Array.isArray(obj) && obj.length === 1 && obj[0] === '');
  }

  /**
   * Checks if an object has no properties.
   * @param obj The object to check.
   * @returns true if the object is empty, otherwise false.
   */
  static isEmptyObj(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  /**
   * Creates a deep clone of an object.
   * @param object The object to clone.
   * @returns A deep clone of the object.
   */
  static clone<T>(object: T): T {
    const json = JSON.stringify(object);
    return JSON.parse(json);
  }

  /**
   * Generate unique IDs for use as pseudo-private/protected names.
   * Similar in concept to <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
   *
   * The goals of this function are twofold:
   *  * Provide a way to generate a string guaranteed to be unique when compared
   *    to other strings generated by this function.
   *
   *  * Make the string complex enough that it is highly unlikely to be
   *    accidentally duplicated by hand (this is key if you're using `ID`
   *    as a private/protected name on an object).
   */
  static uniqid(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 10);
  }

  /**
   * Merges the properties of the source object into the target object deeply.
   * If the source object is empty, the function returns immediately.
   *
   * @param target - The target object to merge properties into.
   * @param source - The source object from which properties are merged.
   *
   * @remarks
   * - This function performs a deep merge, meaning it will recursively merge nested objects.
   * - It skips merging properties with keys '__proto__', 'constructor', and 'prototype' for security reasons.
   * - If the property values in both target and source are objects, it will recursively merge them.
   * - If the property values are not objects or are different, the source value will overwrite the target value.
   */
  static mergeDeep(target: any, source: any): void {
    if (ObjectHelper.isEmpty(source)) {
      return;
    }

    for (const [key, sourceValue] of Object.entries(source)) {
      if (['__proto__', 'constructor', 'prototype'].includes(key)) {
        return;
      }

      const destValue = target[key];
      if (destValue === sourceValue) {
        return;
      }

      if (ObjectHelper.isObject(sourceValue) && ObjectHelper.isObject(destValue)) {
        ObjectHelper.mergeDeep(destValue, sourceValue);
      } else {
        target[key] = sourceValue;
      }
    }
  }

  /**
   * Checks if the given item is an object.
   *
   * @param item - The item to check.
   * @returns `true` if the item is an object and not an array, otherwise `false`.
   */
  private static isObject(item): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }
}
