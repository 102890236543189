import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ObjectHelper } from '@app/one-core/helpers/object-helper';

export class ActionMenuItem {
  key: number | string;
  text?: string;
  icon?: string;
  disabled?: boolean;
  styleClass?: string;
  tooltip?: string;
  subItems?: ActionMenuItem[];
  disabledTooltip?: string;
}

export class ActionMenu {
  loading: boolean;
  items: ActionMenuItem[];
}

@Injectable({
  providedIn: 'root',
})
export class ActionMenuService {
  private actionMenu$: BehaviorSubject<ActionMenu>;
  private actions$ = new Subject<number | string>();

  //  _                     _ _
  // | |                   | (_)
  // | |     ___   __ _  __| |_ _ __   __ _
  // | |    / _ \ / _` |/ _` | | '_ \ / _` |
  // | |___| (_) | (_| | (_| | | | | | (_| |
  // \_____/\___/ \__,_|\__,_|_|_| |_|\__, |
  //                                   __/ |
  //                                  |___/
  //
  setLoading(loading: boolean): void {
    const actionMenu = this.actionMenu$.value;
    this.actionMenu$.next({
      loading: loading,
      items: actionMenu.items,
    });
  }

  //  _____ _
  // |_   _| |
  //   | | | |_ ___ _ __ ___  ___
  //   | | | __/ _ \ '_ ` _ \/ __|
  //  _| |_| ||  __/ | | | | \__ \
  // |_____|\__\___|_| |_| |_|___/
  //
  getItems(): Observable<ActionMenu> {
    if (!this.actionMenu$) {
      this.actionMenu$ = new BehaviorSubject<ActionMenu>({
        loading: false,
        items: [],
      });
    }
    return this.actionMenu$.asObservable();
  }

  setItems(items: ActionMenuItem[]): void {
    this.actionMenu$.next({
      loading: false,
      items: [...items],
    });
  }

  patchItem(data: ActionMenuItem): void {
    const actionMenu = this.actionMenu$.value;
    const items = actionMenu.items;
    let index = items.findIndex((m) => m.key === data.key);

    if (index !== -1) {
      const item = items[index];
      const newItem = Object.assign({}, item, data);

      if (ObjectHelper.equals(item, newItem)) {
        return;
      }

      this.actionMenu$.next({
        loading: false,
        items: [...items.slice(0, index), newItem, ...items.slice(index + 1)],
      });

      return;
    }

    // Patch when there's a sub item that change.

    index = items.findIndex((i) => i.subItems?.some((x) => x.key === data.key));

    if (index !== -1) {
      const item = items[index];
      const subItemIndex = item.subItems.findIndex((i) => i.key === data.key);
      const subItem = item.subItems[subItemIndex];
      const newSubItem = Object.assign({}, subItem, data);

      if (ObjectHelper.equals(subItem, newSubItem)) {
        return;
      }

      item.subItems = [...item.subItems.slice(0, subItemIndex), newSubItem, ...item.subItems.slice(subItemIndex + 1)];

      this.actionMenu$.next({
        loading: false,
        items: [...items.slice(0, index), { ...item }, ...items.slice(index + 1)],
      });
    }
  }

  //               _   _
  //     /\       | | (_)
  //    /  \   ___| |_ _  ___  _ __  ___
  //   / /\ \ / __| __| |/ _ \| '_ \/ __|
  //  / ____ \ (__| |_| | (_) | | | \__ \
  // /_/    \_\___|\__|_|\___/|_| |_|___/
  //
  getActions(): Observable<number | string> {
    if (!this.actions$) {
      this.actions$ = new Subject<number | string>();
    }
    return this.actions$.asObservable();
  }

  dispatchAction(action: number | string): void {
    this.actions$.next(action);
  }
}
